<template>
    <v-container 
        class="overflow-y-auto bg-transparent" 
        :style="{ 'height': height - 100 + 'px', 'min-height': height + 'px', 'max-height': height - 100 + 'px', 'width': '100%' }" fluid grid-list-sm>
    <v-row wrap dense justify-space-around>
      <v-col v-for="(scenario, i) in scenarios" :key="i" cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
        <v-hover>
            <template v-slot:default="{ isHovering, props }">
                <v-card v-bind="props" class="mx-auto" elevation="12" :color="isHovering ? 'grey-darken-2' : undefined" @click="pickScenario(scenario)">
                    <v-card-title>
                        <div class="d-flex align-center justify-space-between">
                            <div>{{ scenario.name }}</div>
                        </div>
                    </v-card-title>
                    <v-card-subtitle v-if="scenario.displayName" class="text-medium-emphasis pt-0 mt-0 pb-0 mb-0">
                        {{ scenario.displayName }}
                    </v-card-subtitle>
                    <v-card-text class="text-medium-emphasis pt-1 mt-0 pb-0 mb-0">
                        <ul style="list-style: none; list-style-type: none; margin: 0; padding: 0; overflow: hidden;">
                            <li style="float: left;"><v-icon>mdi-desktop-classic</v-icon></li>
                            <li class="pl-1" style="float: left;" v-for="(tech, j) in scenario.tech" :key="j">{{ tech }}{{ (j === scenario.tech.length - 1 ? '': ',')}}</li>
                        </ul>
                    </v-card-text>
                    <v-card-text class="text-medium-emphasis pt-1 mt-0 pb-0 mb-0">
                        <ul style="list-style: none; list-style-type: none; margin: 0; padding: 0; overflow: hidden;">
                            <li style="float: left;"><v-icon>mdi-account</v-icon></li>
                            <li class="pl-1" style="float: left;" v-for="(role, j) in scenario.roles" :key="j">{{ role }}{{ (j === scenario.roles.length - 1 ? '': ',')}}</li>
                        </ul>
                    </v-card-text>
                    <v-card-text class="text-medium-emphasis pt-1 mt-0">
                        <ul style="list-style: none; list-style-type: none; margin: 0; padding: 0; overflow: hidden;">
                            <li style="float: left;"><v-icon>mdi-domain</v-icon></li>
                            <li class="pl-1" style="float: left;" v-for="(domain, j) in scenario.domains" :key="j">{{ domain }}{{ (j === scenario.domains.length - 1 ? '': ',')}}</li>
                        </ul>
                    </v-card-text>
                
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon size="96">{{ scenario.icon }}</v-icon>
                    </v-card-actions>
                </v-card>
            </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { inject, onMounted } from 'vue'
import { useDisplay } from 'vuetify'
export default {
name: 'ScenarioPicker',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: Number,
            required: false,
            default: null
        },
        scenarios: {
            type: Array,
            required: false,
            default: () => []
        },
        pickScenarioFunc: {
            type: Function,
            required: false,
            default: async ({ test }) => { console.log(`No handler for selecting scenario: ${JSON.stringify(test)}`) }
        }
    },
components: {
},
setup(props) {
    // Logger
    const logger = inject('vuejs3-logger')

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()

    // Scenario Picker Functions
    const pickScenario = async (test) => {
        await props.pickScenarioFunc({ test })
    }

    // Scenario Picker Event Watchers
    // watch(props.testPalette, () => {
    //   logger.debug(`Test palette changed!`)
    // })

    onMounted(() => {
        logger.debug(`Scenario Picker mounted!`)
    })

    return {
        // Window Size Management
        smAndUp,
        mdAndUp,
        // Scenario Picker Functions
        pickScenario
    }
}
}
</script>

<style lang="scss" scoped>

</style>